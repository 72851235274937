<template>
    <div>
        <section class="register">
            <div class="half_inner height_half_top_inner height_bottom_inner">
                <header class="section_header header_flex">
                    <h2 class="text_xxl text_white text_300">Registro</h2>
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/" class="breadcrumbs_item">Home<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <span class="breadcrumbs_item">Registro</span>
                            </li>
                        </ul>
                    </div>
                </header>
                <div class="content_block padded rounded white">
                    <div class="login_block grid grid_pad flex_space">
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box">
                                <h2 class="text_xl mb30">¡Registrate aquí para poder comprar en Shopohs!</h2>
                                <p class="mb30">¿Ya estas registrado?, <router-link to="/login" style="text-decoration: underline;">Entra en Shophos por aquí</router-link></p>
                            </div>
                        </div>
                        <div class="grid_col grid_col_1_2">
                            <div class="grid_col_box">
                                <form class="form" @submit.prevent="register">
                                    <div class="input_block">
                                        <input type="email" v-model="email" placeholder="Email" maxlength="50"/>
                                    </div>
                                    <div class="input_block">
                                        <input type="password" v-model="password" placeholder="Contraseña"/>
                                    </div>
                                    <div class="input_block">
                                        <input type="password" v-model="password_confirmation" placeholder="Repetir contraseña"/>
                                    </div>
                                    <div class="input_block">
                                        <button type="text">Enviar</button>
                                    </div>
                                    <div class="input_block">
                                        <div class="legal_line">
                                            <p class="legal">Todos los campos son imprescindibles</p>
                                        </div>
                                    </div>
                                    <div class="input_block" v-if="msg">
                                        <div class="message" :class="msg.type">
                                            <h3>{{msg.text}}</h3>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Resgistro',
    data: function(){
        return {
            email: null,
            password: null,
            password_confirmation: null,
            msg: null
        }
    },
    methods:{
        register: function(){
            if(!this.email || this.email.trim() == ''){
                this.msg = {
                    type: 'ko',
                    text: 'Falta el email'
                }
                return;
            }
            else if(!this.password || this.password.trim() == ''){
                this.msg = {
                    type: 'ko',
                    text: 'Falta la password'
                }
                return;
            }
            else if(this.password != this.password_confirmation){
                this.msg = {
                    type: 'ko',
                    text: 'Las contraseñas no coinciden'
                }
                return;
            }
            this.msg = null;

            axios
                .post(process.env.VUE_APP_URL+'users', {
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.$router.push('/login');
                    }
                    else{
                        this.msg = {
                            type: 'ko',
                            text: rs.msg
                        }
                    }
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        }
    }
}
</script>